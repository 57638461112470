




































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    },
  })
  editorBlock
  @Prop({
    default: () => {
      return {}
    },
  })
  editorHandler

  removeBlock(id) {
    const index = this.editorHandler.editorBlocks.findIndex((item) => item.id === id)
    if (index === -1) return
    if (
      this.editorHandler.editorBlocks[index - 1]?.type === 'text-block' &&
      this.editorHandler.editorBlocks[index + 1]?.type === 'text-block'
    ) {
      const ref1 = this.$parent.$refs[`quill-${this.editorHandler.editorBlocks[index - 1].id}`] as any
      const ref2 = this.$parent.$refs[`quill-${this.editorHandler.editorBlocks[index + 1].id}`] as any
      const quill1 = ref1[0].quill as any
      const quill2 = ref2[0].quill
      const originalContent = quill1.getContents(0).ops || []
      const appendedContent = quill2.getContents(0).ops || []

      if (originalContent[0]) {
        originalContent[0].insert += '\n'
      }

      const mergedContent = { ops: [...originalContent, ...appendedContent] }
      quill1.setContents(mergedContent)
      this.editorHandler.removeEditorBlocks(index, 2)
    } else {
      this.editorHandler.removeEditorBlocks(index, 1)
    }
  }

  fileInputChange(event) {
    const files = event.target.files || []
    this.editorHandler.changeImageBlockFileInputs(files, this.editorBlock.id)
  }

  addMediaImageFiles() {
    const editorImageInput = document.getElementById(`editor-image-input-${this.editorBlock.id}`) as any
    editorImageInput.click()
  }
}
